import Swal from "sweetalert2";
import { router } from '@/helpers';

export const logout = {
    perguntaSair
};

export function perguntaSair(){

  Swal.fire({
    title: "Atenção",
    text: 'Deseja realmente sair?',
    icon: "warning",
    customClass:{
      confirmButton: "md-button md-success",
      cancelButton: "md-button md-danger",
    },
    showCancelButton: true,
    confirmButtonText: "Sair",
    cancelButtonText: "Cancelar",
    buttonsStyling: false
  }).then(result => {
    if (result.value) {
      Swal.fire({
        title: "Usuário desconectado",
        text: "Até a próxima!",
        icon: "success",
        buttonsStyling: false,
        timer: 2000,
        showConfirmButton: false
      }).then(result => {
          router.push("/login");
      });
    }
  });


}
