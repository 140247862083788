export function authHeaderPost(formData) {
  let tokenResponse =
    localStorage.getItem("token") || sessionStorage.getItem("token");

  if (tokenResponse) {
    try {
      tokenResponse = JSON.parse(tokenResponse);
    } catch {
      window.location.href = "/login";
    }
    return {
      "Content-Type":
        "multipart/form-data; boundary={" + formData._boundary + "}",
      Accept: "*/*",
      Authorization: "Bearer " + tokenResponse.Token,
    };
  } else {
    return {};
  }
}
