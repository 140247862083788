export function toMoedaPtbr(value) {
    if (typeof value !== "number") {
        return value;
    }
    var formatter = new Intl.NumberFormat('pt-BR', {
        currency: 'BRL',
        style: 'currency'
    });
    return formatter.format(value);
};
