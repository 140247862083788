export const criaCampos = {
    criaValidador,
    montaCampos,
}


  export function criaValidador(nomeParametro, obrigatorio){
  
    var validador = {
      required: obrigatorio
    };
  
    switch(nomeParametro){
      case "CPF":
          validador.ValidadorCpf = true;
        break;
      case "CNPJ":
          validador.ValidadorCnpj = true;
          break;
      case "CEP":
          validador.ValidadorCep = true;
          break;
      case "DATA_NASCIMENTO":
          validador.ValidadorDataPtbr = true;
          break;
      case "DATA_REF":
          validador.validadorDataYMD = true;
          break;
    }
    return validador;
  }
  
 

  export function montaCampos(jsonProdutos){
    var campos = jsonProdutos.campos
    campos.forEach(campo => {
      campo.Validador = criaValidador(campo.Nome)
    })
  
    return campos
  }