export function authHeader() {
  let tokenResponse =
    localStorage.getItem("token") || sessionStorage.getItem("token");
    
  if (tokenResponse) {
    try {
      tokenResponse = JSON.parse(tokenResponse);
    } catch {
      window.location.href = "/login";
    }
    return {
      "Content-Type": "application/json",
      Authorization: "Bearer " + tokenResponse.Token,
    };
  } else {
    return {};
  }
}
