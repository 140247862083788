import { consultasService } from "../services/consultas.service";
import Swal from "sweetalert2";
import { userPreferenceStore } from "../stores/userPreference";
export var alertasHelper = {
  consultaAlertas,
  trataAlertas,
  executaAlerta,
  executaExtra,
};

async function consultaAlertas(
  cod_ref,
  codigo_agrupador,
  campos,
  inferido = {}
) {
  var objetoConsulta = {};

  campos["CPF"] ? (objetoConsulta["cpf_cnpj"] = campos["CPF"]) : "";

  campos["CEP"] ? (objetoConsulta["cep"] = campos["CEP"]) : "";

  campos["CNPJ"] ? (objetoConsulta["cpf_cnpj"] = campos["CNPJ"]) : "";
  campos["CNPJ"] ? (objetoConsulta["tipo_pessoa"] = "pessoa_juridica") : "";

  objetoConsulta["cod_ref"] = cod_ref;
  objetoConsulta["modo_resultado"] = "codigo";
  objetoConsulta["cod_agrupador"] = codigo_agrupador;
  inferido.cod_transacao
    ? (objetoConsulta["cod_transacao_ciclica_pai"] = inferido.cod_transacao)
    : "";

  var retorno = await consultasService
    .realizaConsulta(objetoConsulta)
    .then((resultado) => {
      return resultado;
    });
  return retorno;
}

function trataAlertas(resultado, alertasJSON) {
  var alertas = [];

  const atributos = resultado.atributos;
  Object.keys(atributos).forEach((alerta) => {
    if (atributos[alerta] && alertasJSON[alerta]) {
      alertas.push({
        key: alerta,
        Nome: alertasJSON[alerta].Nome,
        Recomendado: alertasJSON[alerta].Recomendado,
        Valor: alertasJSON[alerta].Valor,
        Recomendado_Nome: alertasJSON[alerta].Recomendado_Nome,
        Indicador: alertasJSON[alerta].Indicador,
      });
    }
  });

  var auxList = [];
  alertas = alertas.filter((alerta) => {
    if (auxList.includes(alerta.Nome)) {
      return false;
    }
    auxList.push(alerta.Nome);
    return true;
  });

  return alertas;
}

async function executaAlerta(alerta, contexto) {
  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  if (
    contexto.requisitados[alerta.Recomendado] ||
    contexto.obrigatorio[alerta.Recomendado]
  ) {
    Toast.fire({
      icon: "info",
      title: `Consulta do ${alerta.Recomendado_Nome} já realizada`,
    });
    const box = document.getElementById(alerta.Recomendado + contexto.id);
    box.classList.add("destaque");
    const content = document.querySelector(".content");
    content.classList.add("margin-bottom-adapter");
    box.scrollIntoView();
    setTimeout(() => {
      content.classList.remove("margin-bottom-adapter");
      box.classList.remove("destaque");
    }, 5000);

    return false;
  }
  // return;

  return await Swal.fire({
    title: "Atenção",
    text: `Essa ação realizará a consulta do produto ${alerta.Recomendado_Nome}.`,
    icon: "warning",
    customClass: {
      confirmButton: "md-button md-success",
      cancelButton: "md-button md-danger",
    },
    showCancelButton: true,
    confirmButtonText: "Consultar",
    cancelButtonText: "Cancelar",
    buttonsStyling: false,
  }).then((result) => {
    if (!result.value) {
      Toast.fire({
        icon: "error",
        title: "Consulta de alerta cancelada",
      });
    } else {
      Toast.fire({
        icon: "success",
        title: "Consulta de alerta realizada",
      });
      return true;
    }
  });
}

async function executaExtra(nome, valor) {
  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  // const store = userPreferenceStore();

  // const pref = await store.syncPreferenciaConsultaAdicional();

  // if (pref) {
  Toast.fire({
    icon: "success",
    title: "Consulta de produto disponível realizada",
  });
  return true;
  // }

  // return await Swal.fire({
  //   title: "Atenção",
  //   text: `Essa ação realizará a consulta do produto ${nome}, que terá um custo de ${valor} boxes`,
  //   icon: "warning",
  //   customClass: {
  //     confirmButton: "md-button md-success",
  //     cancelButton: "md-button md-danger",
  //   },
  //   showCancelButton: true,
  //   confirmButtonText: "Consultar",
  //   cancelButtonText: "Cancelar",
  //   buttonsStyling: false,
  // }).then((result) => {
  //   if (!result.value) {
  //     Toast.fire({
  //       icon: "error",
  //       title: "Consulta de produto disponível cancelada",
  //     });
  //   } else {
  //     Toast.fire({
  //       icon: "success",
  //       title: "Consulta de produto disponível realizada",
  //     });
  //     return true;
  //   }
  // });
}
