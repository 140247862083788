export function toInteiroPtbr(value) {

    if (typeof value !== "number") {
        return value;
    }
    var formatter = new Intl.NumberFormat('pt-BR', {
        useGrouping: true,
        maximumSignificantDigits: 9
    });
    return formatter.format(value);
};
